/*
 * @Author       : JiangChao
 * @Date         : 2024-04-15 14:06:42
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-08-09 16:16:04
 * @Description  : 
 */
export default {
    setSelectedTimeZone(state, timeZone) {
      state.selectedTimeZone = timeZone;
    },

    setSelectedTempType(state, tempType) {
      state.selectedTempType = tempType;
    },

    setSelectedLanguage(state, language) {
      state.selectedLanguage = language;
    },
    
}


