<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-22 13:58:12
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-09 11:01:58
 * @Description  : 
-->
<template>
  <div>
    <TopHeader></TopHeader>

    <div>
      <div class="slider-bar">
        <div
          class="menu-item"
          @click="goDashboard()"
          :class="active == 1 ? 'is-active' : ''"
        >
          <img
            src="../../assets/ic_board.png"
            alt=""
            style="width: 40px; height: 40px"
          />
        </div>
        <span class="font-heavy"> {{ $t("dashboard") }}</span>

        <div
          class="menu-item"
          @click="goAlert()"
          :class="active == 2 ? 'is-active' : ''"
        >
          <img
            src="../../assets/ic_scene.png"
            style="width: 30px; height: 30px"
          />
        </div>
        <span class="font-heavy">{{ $t("alert_setting") }}</span>

        <div
          class="menu-item"
          @click="goLogger()"
          :class="active == 3 ? 'is-active' : ''"
        >
          <img
            src="../../assets/ic_device.png"
            style="width: 28px; height: 28px"
          />
        </div>
        <span class="font-heavy">{{ $t("logger") }}</span>

        <div
          class="menu-item"
          @click="goShipment()"
          :class="active == 4 ? 'is-active' : ''"
        >
          <img
            src="../../assets/ic_shipment.png"
            style="width: 30px; height: 30px"
          />
        </div>
        <span class="font-heavy">{{ $t("shipment") }}</span>
        <div
          class="menu-item"
          @click="goOrgan()"
          :class="active == 5 ? 'is-active' : ''"
        >
          <img
            src="../../assets/ic_organ.png"
            style="width: 30px; height: 30px"
          />
        </div>
        <span class="font-heavy">{{ $t("organization") }}</span>

        <div
          class="menu-item"
          @click="goUserCenter()"
          :class="active == 6 ? 'is-active' : ''"
        >
          <img
            src="../../assets/ic_user.png"
            style="width: 24px; height: 24px"
          />
        </div>
        <span class="font-heavy">{{ $t("user") }}</span>
      </div>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import TopHeader from "../../pages/components/TopHeader.vue";

export default {
  name: "MenuRouter",
  components: {
    TopHeader,
  },
  data() {
    return {
      active: 1,
    };
  },
  watch: {
    $route: "updateActiveBasedOnRoute", // 监听路由变化
  },
  methods: {
    goDashboard() {
      if (this.$route.path !== "/home/board") {
        this.goPages("/home/board");
        this.active = 1;
      }
    },
    // goScene() {
    //   if (this.$route.path !== "/home/scene") {
    //     this.goPages("/home/scene");
    //     this.active = 2;
    //   }
    // },
    goAlert() {
      if (this.$route.path !== "/home/alert") {
        this.goPages("/home/alert");
        this.active = 2;
      }
    },
    goLogger() {
      if (this.$route.path !== "/home/logger") {
        this.goPages("/home/logger");
        this.active = 3;
      }
    },
    goShipment() {
      if (this.$route.path !== "/home/shipment") {
        this.goPages("/home/shipment");
        this.active = 3;
      }
    },
    goOrgan() {
      if (this.$route.path !== "/home/organ") {
        this.goPages("/home/organ");
        this.active = 5;
      }
    },
    goUserCenter() {
      if (this.$route.path !== "/home/user") {
        this.goPages("/home/user");
        this.active = 6;
      }
    },

    // 更新 active 的值，根据当前路由
    updateActiveBasedOnRoute() {
      const currentPath = this.$route.path;
      if (currentPath.includes("/home/board")) {
        this.active = 1;
      } else if (
        // currentPath.includes("/home/scene") ||
        // currentPath.includes("/createScene") ||
        // currentPath.includes("/sceneDetail") ||
        // currentPath.includes("/createSceneWithoutTemplate") ||
        // currentPath.includes("/createSceneWithTemplate")
        currentPath.includes("/home/alert") ||
        currentPath.includes("/createAlert") ||
        // currentPath.includes("/alertDetail") ||
        currentPath.includes("/createAlertWithoutTemplate") ||
        currentPath.includes("/createAlertWithTemplate")
      ) {
        this.active = 2;
      } else if (
        currentPath.includes("/home/logger") ||
        currentPath.includes("/loggerDetail") ||
        currentPath.includes("/loggerInfoExport")
      ) {
        this.active = 3;
      } else if (
        currentPath.includes("/home/shipment") ||
        currentPath.includes("/createShipment") ||
        currentPath.includes("/editShipment") ||
        currentPath.includes("/shipmentDetail") ||
        currentPath.includes("/createShipmentWithoutTemplate") ||
        currentPath.includes("/createShipmentWithTemplate")
      ) {
        this.active = 4;
      } else if (
        currentPath.includes("/home/organ") ||
        currentPath.includes("/organDetail")
      ) {
        this.active = 5;
      } else if (currentPath.includes("/home/user")) {
        this.active = 6;
      }
    },
  },
  props: {},
  computed: {},
  mounted() {
    // 根据当前路由设置 active 的值
    this.updateActiveBasedOnRoute();
    // this.goDashboard();
  },
  created() {},
};
</script>

<style scoped lang="less">
.slider-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #30455c;
  padding: 20px 0;
  position: fixed; /* 固定在左侧 */
  top: 66px; /* 距离顶部导航栏的距离 */
  left: 0;
  width: 90px;
  bottom: 0;
  // height: calc(100% - 10vh;); /* 计算剩余高度 */
  box-sizing: border-box; /* 盒子模型设置为边框盒 */
  .menu-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    padding: 10px;
    border-radius: 12px;
    background: #7d8694;
    width: 30px;
    height: 30px;
    cursor: pointer;
    img {
      width: 35px;
      height: 35px;
    }
  }
  .is-active {
    color: white;
    font-size: 16px;
    font-weight: Normal;
    padding: 10px;
    text-align: center;
    background: #1a72f4;
    width: 30px;
    height: 30px;
    img {
      width: 35px;
      height: 35px;
    }
  }
  span {
    color: white;
    font-size: 12px;
  }
}
.content {
  position: fixed; /* 固定在左侧 */
  top: 66px; /* 距离顶部导航栏的距离 */
  right: 0;
  height: calc(100% - 66px;); /* 计算剩余高度 */
  width: calc(100% - 90px;); /* 计算剩余高度 */
  box-sizing: border-box; /* 盒子模型设置为边框盒 */
  overflow-y: scroll;
}
/* 清除浮动 */
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}
</style>
