/*
 * @Author       : JiangChao
 * @Date         : 2022-01-19 14:42:30
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-08-16 13:43:15
 * @Description  : 
 */
import { axios } from './axios'

const httpAction = (params) => axios(params)

// get 方法
const getAction = (url, params) => axios({
	url: url,
	method: 'get',
	params: params
});

// post
const postAction = (url, params) => axios({
	url: url,
	method: 'post',
	data: params
});

// post
const postBlobAction = (url, params) => axios({
	url: url,
	method: 'post',
	data: params,
	responseType: 'blob',
});

export {
	httpAction,
	getAction,
	postAction,
	postBlobAction
};