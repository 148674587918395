/*
 * @Author       : JiangChao
 * @Date         : 2024-08-23 15:12:16
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-14 14:20:27
 * @Description  :
 */
import Vue from "vue";
import moment from "moment";
import i18n from "../locales/index";

// 定义全局过滤器
Vue.filter("formatDateWithTimeZone", function(
  value,
  timezoneOffsetMinutes,
  isZh
) {
  if (!value) return "-";

  // 将分钟转换为小时
  const timezoneOffsetHours = timezoneOffsetMinutes / 60;

  // 将时间戳转换为 Moment 对象
  const momentDate = moment.utc(value * 1000); // 乘以1000是因为 Moment.js 使用毫秒为单位

  // 应用时区偏移
  const dateInSelectedTimeZone = momentDate
    .clone()
    .utcOffset(timezoneOffsetHours);

  // 根据 isChina 字段选择不同的时间格式
  let formatString = isZh == "zh" ? "YYYY-MM-DD HH:mm" : "DD-MM-YYYY HH:mm";

  // 格式化时间
  const formattedDate = dateInSelectedTimeZone.format(formatString);

  return formattedDate;
});
// 定义全局过滤器
Vue.filter("convertTemperature", function(value, unit) {
  if (typeof value !== "number") return "-";

  if (unit === "°C") {
    // 如果单位是摄氏度，直接返回原值
    return value.toFixed(1);
  } else if (unit === "°F") {
    // 如果单位是华氏度，进行转换
    const fahrenheit = (value * 9) / 5 + 32;
    return fahrenheit.toFixed(1);
  } else {
    return "-";
  }
});

Vue.filter("AlarmType", function(type) {
  const types = {
    "0": "Single measurement",
    "1": "MKT",
    "2": "Cumulative time",
  };

  return types[type] || "-";
});

Vue.filter("AlarmSensor", function(value) {
  const values = {
    "1": "Temperature",
    "2": "Humidity",
    "4": "Probe temperature",
    "8": "Probe Humidity",
    "16": "Battery",
    "32": "Light",
    "64": "Shock",
  };

  return values[value] || "-";
});

// Vue.filter('AlarmConditions', function (value) {
//   const values = {
//       '0': '> more than',
//       '1': '< less than'
//   };

//   return values[value] || 'Unknown';
// });

Vue.filter("deviceIdsSeparated", function(array) {
  if (!Array.isArray(array)) {
    return "";
  }
  return array.join(", ");
});

Vue.filter("TransModel", function(value) {
  // console.log("TransModel---value: ", value);
  const values = {
    1: "land",
    2: "sea",
    3: "air",
    4: "railway",
    5: "warehouse",
    6: "port",
    7: "others",
  };
  return i18n.t(values[value]) || "-";
});

Vue.filter("UploadInterval", function(value) {
  // console.log("UploadInterval---value: ", value);
  const values = {
    1: "1 Minute",
    5: "5 Minutes",
    10: "10 Minutes",
    15: "15 Minutes",
    30: "30 Minutes",
    45: "45 Minutes",
    60: "60 Minutes",
    90: "90 Minutes",
    120: "120 Minutes",
    600: "6 Hours",
    720: "12 Hours",
    1440: "24 Hours",
    2880: "48 Hours",
  };
  return values[value] || "-";
});


Vue.filter("daysAgo", function(timestamp) {
  const now = new Date().getTime();
    const inputTime = timestamp * 1000;
    const diffTime = now - inputTime;
    const daysPassed = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return `${daysPassed} `+i18n.t('days_ago');
});