<!--
 * @Author       : JiangChao
 * @Date         : 2022-03-25 14:49:33
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-04-17 14:51:58
 * @Description  : 
-->
<template>
  <div class="container">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  props: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less">
@import "./style/icon/iconfont.less";
@import "./style/mixins.less";
</style>
