/*
 * @Author       : JiangChao
 * @Date         : 2022-01-19 14:42:23
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-09-27 13:20:41
 * @Description  :
 */
import Vue from "vue";
import axios from "axios";
import config from "@/env.config.js";
import md5 from "js-md5";
import cookies from "vue-cookies";
import i18n from "../locales";
import { MessageBox } from "element-ui";

let loading = "";

let timestamp = new Date().getTime();
let isSessionExpiredShown = false; // 添加一个全局变量
// function createAxiosInstance(baseURL) {
const newAxios = axios.create({
  baseURL: config.baseURL,
  timeout: 50000,
  headers: {
    "Content-Type": "application/json",
  },
});
const err = (response) => {
  if (response.data) {
    switch (response.data.status) {
      case "OVERTIME_LOGIN":
        if (!isSessionExpiredShown) {
          // 只有当未显示过时才显示
          isSessionExpiredShown = true; // 设置标志为已显示
          MessageBox.confirm(i18n.t("OVERTIME_LOGIN"), i18n.t("tip"), {
            confirmButtonText: i18n.t("confirm"),
            // cancelButtonText: i18n.t("cancel"),
            showCancelButton: false,
            type: "warning",
          }).then(() => {
            cookies.remove("token");
            localStorage.clear();
            Vue.prototype.goPages("/login");
          });
        }
        break;
      default:
        // console.error({
        //   code: `${response.data.status}`,
        //   message: `${response.data.message}`,
        //   errApi: response.config.url,
        // });
        Vue.prototype.$message({
          message: i18n.t(response.data.status),
          type: "error",
        });
    }
  }
  return Promise.reject(response.data);
};

//http 请求 拦截器
newAxios.interceptors.request.use(
  (conf) => {
    isSessionExpiredShown = false; // 添加一个全局变量
    // console.log("conf: ", conf);
    loading = Vue.prototype.$loading({
      lock: true,
      // text: '努力加载中...',
      // spinner: 'el-icon-loading',
      background: "rgba(0, 0, 0, 0.0)",
    });

    // 在发送请求之前做些什么
    if (conf.data instanceof FormData) {
      // console.log("multi");
      conf.headers["Content-Type"] = null;
    }
    // 请求头的AUTHORIZATION 组成：Bearer + 空格 + token + 13位时间戳 + : + MD5(token + 13位时间戳 + url)
    // token：登录接口返回的token值， url：完整的请求路径，
    // 请求头详细内容见参数-Headers
    // 如果存在 token，添加到头部中
    // console.log("token: ", token + "${token}---" + `${token}`);
    // console.log("timestamp: ", timestamp + "${timestamp}---" + `${timestamp}`);

    // console.log("config.baseURL: ", `${conf.baseURL}`);

    // console.log("config.url: ", `${conf.url}`);
    let token = cookies.get("token");
    //  if (token) {
    conf.headers.Authorization =
      `Bearer ${token}${timestamp}:` + md5(`${token}${timestamp}${conf.url}`);
    conf.headers.frame_version = "0.0.1"; //版本更新了version也要跟着更新
    conf.headers.frame_language = localStorage.getItem("selectedLanguage")|| "en"; //动态
    conf.headers.frame_source = "Web";
    conf.headers.frame_device = "123.0.6312.124"; //动态

    // }

    // 打印出请求的 URL（可选）
    // console.log("请求地址：", conf.url);
    return conf;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
newAxios.interceptors.response.use((response) => {
  setTimeout(() => {
    loading.close();
  }, 500);

  if (response.config.responseType === "blob") {
    return response;
  }

  if (String(response.data.status) === "0000") {
    return response.data;
  } else {
    return err(response);
  }
}, err);
// 添加一个用于更新baseURL的方法
export function updateBaseUrl(newBaseUrl) {
  newAxios.defaults.baseURL = newBaseUrl;
}

export { newAxios as axios };
