/*
 * @Author       : JiangChao
 * @Date         : 2024-04-22 14:30:45
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-08-20 17:30:10
 * @Description  : 设置 Vue.js 应用程序的国际化配置
 */

// 导入 Vue 和 VueI18n
import Vue from "vue";
import VueI18n from "vue-i18n";

// 导入 Element UI 的国际化相关模块
// import { LocalePlugin } from "element-ui"; // 注意这里使用 LocalePlugin
import locale from "element-ui/lib/locale";

import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import enLocale from "element-ui/lib/locale/lang/en";
import koLocale from "element-ui/lib/locale/lang/ko";
import deLocale from "element-ui/lib/locale/lang/de";
import jaLocale from "element-ui/lib/locale/lang/ja";

// 导入自定义的语言 JSON 文件
import enCustom from "./en.json";
import zhCustom from "./zh.json";
import koCustom from "./ko.json";
import deCustom from "./de.json";
import jaCustom from "./ja.json";

// 使用 VueI18n 插件
Vue.use(VueI18n);

// 创建 messages 对象，结合 Element UI 的语言包和自定义消息
const messages = {
  en: {
    ...enLocale,
    ...enCustom,
  },
  zh: {
    ...zhLocale,
    ...zhCustom,
  },
  ko: {
    ...koLocale,
    ...koCustom,
  },
  de: {
    ...deLocale,
    ...deCustom,
  },
  ja: {
    ...jaLocale,
    ...jaCustom,
  },
};

// 创建 VueI18n 实例
const i18n = new VueI18n({
  // 设置默认语言
  // locale: "en",
  messages,
});

// 使用 Element UI 的 LocalePlugin 并设置 i18n 函数
// Vue.use(LocalePlugin); // 使用 LocalePlugin
locale.i18n((key, value) => i18n.t(key, value)); // 设置 Element UI 的 i18n 函数

// 导出 VueI18n 实例
export default i18n;
