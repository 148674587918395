/*
 * @Author       : JiangChao
 * @Date         : 2024-04-15 14:06:42
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-08-09 16:13:10
 * @Description  :
 */
// import { selectApi } from "@/api/api.js";

export default {
  updateSelectedTimeZone({ commit }, timeZone) {
    commit("setSelectedTimeZone", timeZone);
  },

  updateSelectedTempType({ commit }, tempType) {
    commit("setSelectedTempType", tempType);
  },

  updateSelectedLanguage({ commit }, language) {
    commit("setSelectedLanguage", language);
  },
};
