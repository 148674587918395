<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-16 17:30:09
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-07 09:57:59
 * @Description  : 
-->
<template>
  <div class="container">
    <div class="top">
      <div class="left">
        <img src="../../assets/ic_home_logo.png"/>
        <span class="font-heavy">Logcloud</span>
      </div>
      <div class="right">
        <div class="dropdown-item">
          <el-dropdown>
            <div class="setting-style font-heavy">
              <img
                src="../../assets/ic_setting.png"
                width="20"
                height="20"
                alt=""
              />
              <span class="el-dropdown-link">
                {{ $t("setting") }}

                <img
                  class="custom-dropdown-icon"
                  src="../../assets/ic_down_arrow.png"
                />
              </span>
            </div>
            <el-dropdown-menu slot="dropdown" class="custom-dropdown-menu">
              <el-dropdown-item
                @click.native="goUserCenter"
                class="drop-box"
                style="background:white;color:#3F3F3F"
              >
                <img src="../../assets/ic_account.png" alt="" />
                {{ $t("account") }}</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="logOut"
                class="drop-box"
                style="background:white;color:#EA4C4C"
              >
                <img src="../../assets/ic_logout.png" alt="" srcset="" />
                {{ $t("log_out") }}
              </el-dropdown-item>
              <el-dropdown-item style="background: #1a72f4;color:white">
                {{ $t("logger_in") }} :{{ username }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="dropdown-item">
          <el-dropdown>
            <div class="help-style font-heavy">
              <img
                src="../../assets/ic_help.png"
                width="20"
                height="20"
                alt=""
              />
              <span class="el-dropdown-link">
                {{ $t("help") }}
                <img
                  class="custom-dropdown-icon"
                  src="../../assets/ic_down_arrow.png"
                />
              </span>
            </div>
            <el-dropdown-menu slot="dropdown" class="custom-dropdown-menu2">
              <el-dropdown-item class="drop-box" @click.native="getHelp">
                <img src="../../assets/ic_get_help.png" alt="" />
                {{ $t("get_help") }}
              </el-dropdown-item>
              <el-dropdown-item class="drop-box" @click.native="helpCenter">
                <img src="../../assets/ic_help_center.png" alt="" />{{
                  $t("help_center")
                }}</el-dropdown-item
              >
              <el-dropdown-item class="drop-box" @click.native="changeLog">
                <img src="../../assets/ic_changelog.png" alt="" />{{
                  $t("changelog")
                }}
              </el-dropdown-item>
              <el-dropdown-item class="drop-box" @click.native="reportBug">
                <img src="../../assets/ic_report_a_bug.png" alt="" />{{
                  $t("report_a_bug")
                }}
              </el-dropdown-item>
              <el-dropdown-item
                class="drop-box"
                style="  border-top: 1px solid #DBDBDB;"
                @click.native="getStartGuide"
              >
                <img src="../../assets/ic_guide.png" alt="" />
                {{ $t("getting_started_guide") }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="dropdown-item">
          <!-- <span class="el-dropdown-link">
            {{ $t("temperature")
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span> -->
          <div class="tab-box">
            <div
              class="tab-item"
              :class="isActive == '°C' ? 'is-active' : ''"
              @click="clickTab('°C')"
            >
              °C
            </div>
            <div
              class="tab-item"
              :class="isActive == '°F' ? 'is-active' : ''"
              @click="clickTab('°F')"
            >
              °F
            </div>
          </div>
        </div>
        <div class="dropdown-item">
          <el-dropdown trigger="click" @command="handleCommand">
            <div class="language-style">
              <img src="../../assets/ic_language.png" alt="" />
              <!-- <span class="el-dropdown-link">
                {{ $t("language")}}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span> -->
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="zh">
                {{ $t("chinese") }}</el-dropdown-item
              >
              <el-dropdown-item command="en">
                {{ $t("english") }}</el-dropdown-item
              >
              <el-dropdown-item command="de">
                {{ $t("german") }}</el-dropdown-item
              >
              <el-dropdown-item command="ja">
                {{ $t("japanese") }}</el-dropdown-item
              >
              <el-dropdown-item command="ko">
                {{ $t("korean") }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="dropdown-item">
          <el-dropdown @command="handleTimezoneCommand">
            <div class="timezone-style font-heavy">
              <img
                style="margin-left:40px;margin-right:13px"
                src="../../assets/ic_timezone.png"
                alt=""
              />
              <div class="timezone-right">
                <div style="font-weight:500">{{ $t("timezone") }}：</div>
                <div>{{ timeZoneName }}</div>
              </div>
              <img
                style="margin-left:20px;width:16px;height:16px;margin-right:34px"
                src="../../assets/ic_arrow_down.png"
                alt=""
              />

              <!-- <span class="el-dropdown-link">
                <div>
                  
                </div>
                {{ $t("timezone") }}：{{selectedTimeZone}}<i
                  class="el-icon-arrow-down el-icon--right"
                ></i>
              </span> -->
            </div>

            <el-dropdown-menu slot="dropdown" class="custom-scrollbar">
              <el-dropdown-item
                v-for="(item, index) in timezoneList"
                :key="index"
                :value="item"
                :command="index"
                >{{ item.name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logOutApi, selectApi, setTimezoneApi } from "@/api/api.js";

export default {
  name: "",
  data() {
    return {
      username: "",
      isActive: "°C",
      timezoneList: [],
      timeZoneName: "UTC+00:00",
    };
  },
  methods: {
    clickTab(type) {
      this.isActive = type;
      // console.log("clickTab---type---" + type);
      this.$store.dispatch("updateSelectedTempType", type);
      localStorage.setItem("selectedTempType", type);
    },
    handleCommand(command) {
      console.log("command: ", command);
      this.$store.dispatch("updateSelectedLanguage", command);
      localStorage.setItem("selectedLanguage", command);
    },

    logOut() {
      logOutApi({
        token: this.$cookies.get("token"),
      }).then(() => {
        // console.log("=====", r);
        this.$message.success(this.$t("log_out_success"));
        this.$cookies.set("token", "");
        this.$cookies.remove("token");
        localStorage.clear();
        this.goPages("/login");
      });
    },
    getTimezone() {
      selectApi({ type: "Timezone" }).then((r) => {
        this.timezoneList = r.data;
        // console.log(" this.timezoneList: ", this.timezoneList);
      });
    },
    setTimezone(name, value) {
      setTimezoneApi({
        timezone: value,
        code: name,
      }).then(() => {
        this.$message.success(this.$t("operation_successful"));
      });
    },
    handleTimezoneCommand(command) {
      console.log("command: ", this.timezoneList[command].value);
      this.timeZoneName = this.timezoneList[command].name;
      this.setTimezone(this.timeZoneName, this.timezoneList[command].value);
      localStorage.setItem("timeZoneName", this.timeZoneName);
      localStorage.setItem("timeZoneValue", this.timezoneList[command].value);
      this.$store.dispatch(
        "updateSelectedTimeZone",
        this.timezoneList[command].value
      );
    },
    goUserCenter() {
      this.goPages("/home/user");
    },
    getHelp() {
      this.$message.success("getHelp");
    },
    helpCenter() {
      this.$message.success("helpCenter");
    },
    changeLog() {
      this.$message.success("changeLog");
    },
    reportBug() {
      this.$message.success("reportBug");
    },
    getStartGuide() {
      this.$message.success("getStartGuide");
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },
  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.timeZoneName = localStorage.getItem("timeZoneName") || "UTC+00:00";
    this.isActive = localStorage.getItem("selectedTempType") || "°C";
    // console.log("this.isActive: ", this.isActive);
    this.clickTab(this.isActive);
    this.getTimezone();
    this.username = localStorage.getItem("username");
  },
  created() {},
};
</script>

<style scoped lang="less">
.top {
  height: 66px;
  line-height: 66px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed; /* 固定在顶部 */
  top: 0;
  left: 0;
  width: 100%;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 125px;
      // height: 41px;
      height: 41px;
      padding: 13px 15px;
    }
    span {
      font-weight: 500;
      font-size: 18px;
      // padding: 10px;
      color: #101010;
    }
  }
  .right {
    display: flex;
    flex-direction: row;
    .dropdown-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      // margin: 0 10px;
    }
  }
}
.el-dropdown-link {
  cursor: pointer;
  margin-left: 10px;
  color: #101010;
}

// .custom-dropdown-link .el-icon-arrow-down {
//   display: none; /* 隐藏默认的箭头图标 */
// }
.custom-dropdown-icon {
  /* 设置自定义图标的样式，如大小、颜色等 */
  vertical-align: middle;
  // margin-left: 4px; /* 根据需要调整图标与文本之间的间距 */
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.tab-box {
  display: flex;
  background: white;
  flex-direction: row;
  align-items: center;
  line-height: 40px;
  height: 20px;
  border-right: 1px solid #bbbbbb;
  // padding-right: 10px;
  cursor: pointer;
  width: 140px;
  justify-content: center;
}
.tab-item {
  font-size: 14px;
  text-align: center;
  color: white;
  justify-content: center;
  background: #bbbaba;
  height: 22px;
  line-height: 22px;
  width: 22px;
  border-radius: 50%;
  margin: 0 6px;
}

.is-active {
  background: #1a72f4;
  font-size: 16px;
  font-weight: Normal;
  text-align: center;
  color: #ffffff;
}
.setting-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  border-right: 1px solid #bbbbbb;
  padding-right: 10px;
}

.help-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  border-right: 1px solid #bbbbbb;
  // padding-right: 10px;
  width: 142px;
  justify-content: center;
}
.language-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // height: 40px;
  line-height: 20px;
  height: 20px;
  border-right: 1px solid #bbbbbb;
  // padding-right: 10px;
  cursor: pointer;
  width: 116px;

  img {
    width: 25px;
    height: 25px;
    // margin-right: 10px;
  }
}
.timezone-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  line-height: 15px;
  height: 15px;
  width: 239px;

  justify-content: center;
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .timezone-right {
    display: flex;
    flex-direction: column;
    color: #101010;
    font-size: 12px;
  }
}
.drop-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #1a72f4;
  img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
}
.custom-dropdown-menu {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-color: #1a72f4 !important;
}

.custom-dropdown-menu2 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.custom-scrollbar {
  max-height: 300px; /* 你可以根据需要调整这个高度 */
  overflow-y: auto;
}

/* 添加滚动条样式，可选 */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
</style>
