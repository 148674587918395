// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./style/icon/iconfont.woff2?t=1648357369357");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./style/icon/iconfont.woff?t=1648357369357");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./style/icon/iconfont.ttf?t=1648357369357");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face{font-family:iconfont;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\")}.iconfont{font-family:iconfont!important;font-size:16px;font-style:normal;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.icon-caozuo_chushi:before{content:\"\\e677\"}.icon-automatic-o:before{content:\"\\e988\"}.icon-a-224fengchuangbolichuwuchushuang:before{content:\"\\e605\"}.icon-songfeng:before{content:\"\\e674\"}.icon-taiyang:before{content:\"\\e636\"}.icon-fengsu-xiao:before{content:\"\\e690\"}.icon-fengsu-zhong:before{content:\"\\e696\"}.icon-fengsu-da:before{content:\"\\e697\"}.icon-xiaochengxu_kongtiaowendu:before{content:\"\\e600\"}.phone-adaptation{max-width:1300px;min-width:1300px;margin:0 auto}.body-bg,body{background-color:#f1f2f4;font-size:14px}.bg-fff{background-color:#fff}.body-bg,.color-fff{color:#fff}.app-content{margin:0 auto;min-height:100%;box-sizing:border-box;color:#2c2e30}.app-content img{width:100%}.app-content .content{padding:0 15px;margin:0 auto}.app-content .fixed-centent{left:0;right:0}.app-content .footer,.app-content .van-popup,.app-content .van-sticky--fixed,.app-content .van-submit-bar,.app-content .van-tabbar--fixed{max-width:1300px;min-width:1300px;margin:0 auto;left:0;right:0}.app-content .van-popup--center{left:50%;width:85%}.spacing-10{height:10px;background-color:#f1f2f4}@supports (bottom:env(safe-area-inset-bottom)){.safe-area-inset-bottom{padding-bottom:constant(safe-area-inset-bottom);padding-bottom:env(safe-area-inset-bottom)}}", ""]);
// Exports
module.exports = exports;
