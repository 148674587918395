/*
 * @Author       : JiangChao
 * @Date         : 2024-04-15 14:06:42
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-08 20:47:22
 * @Description  :
 */
import Vue from "vue";
import VueRouter from "vue-router";
import App from "../App.vue";
import MenuRouter from "../components/layouts/MenuRouter.vue";
import i18n from "../locales/index";
// console.log("i18n: ", i18n);

Vue.use(VueRouter);
const routes = [
  {
    path: "/home",
    name: "home",
    meta: {
      index: 1,
      title: i18n.t("home"),
    },
    // redirect: "board",
    component: MenuRouter,
    children: [
      // {
      //   path: "index",
      //   name: "home/index",
      //   // redirect: '/home',
      //   component: () => import("../pages/home/Home.vue"),
      //   // meta: { title: '在线开发', icon: 'dashboard', permission: ['dashboard'] },
      // },

      {
        path: "board",
        name: "Board",
        meta: {
          index: 1,
          // title: i18n.t("dashboard"),
          title: "logcloud",
        },
        component: () => import("@/pages/board/Board.vue"),
      },

      {
        path: "logger",
        name: "Logger",
        meta: {
          index: 2,
          // title: i18n.t("logger"),
          title: "logcloud",
        },
        component: () => import("@/pages/logger/Logger.vue"),
      },
      {
        path: "organ",
        name: "Organ",
        meta: {
          index: 3,
          // title: i18n.t("organ"),
          title: "logcloud",
        },
        component: () => import("@/pages/organ/Organ.vue"),
      },
      // {
      //   path: "scene",
      //   name: "Scene",
      //   meta: {
      //     index: 4,
      //     // title: i18n.t("scene"),
      //     title: "logcloud",
      //   },
      //   component: () => import("@/pages/scene/Scene.vue"),
      // },
        {
        path: "shipment",
        name: "Shipment",
        meta: {
          index: 5,
          // title: i18n.t("scene"),
          title: "logcloud",
        },
        component: () => import("@/pages/shipment/Shipment.vue"),
      },

      
      {
        path: "alert",
        name: "Alert",
        meta: {
          index: 4,
          // title: i18n.t("forget_password"),
          title: "logcloud",
        },
        component: () => import("@/pages/alert/Alert.vue"),
      },
      {
        path: "user",
        name: "User",
        meta: {
          index: 5,
          // title: i18n.t("user"),
          title: "logcloud",
        },
        component: () => import("@/pages/user/User.vue"),
      },
      {
        path: "/loggerDetail",
        name: "LoggerDetail",
        meta: {
          index: 6,
          // title: i18n.t("logger_detail"),
          title: "logcloud",
        },
        component: () => import("@/pages/logger/LoggerDetail.vue"),
      },

      {
        path: "/shipmentDetail",
        name: "ShipmentDetail",
        meta: {
          index: 7,
          // title: i18n.t("logger_detail"),
          title: "logcloud",
        },
        component: () => import("@/pages/shipment/ShipmentDetail.vue"),
      },


      
      {
        path: "/loggerInfoExport",
        name: "LoggerInfoExport",
        meta: {
          index: 8,
          // title: i18n.t("logger_info_export"),
          title: "logcloud",
        },
        component: () => import("@/pages/logger/LoggerInfoExport.vue"),
      },

      // {
      //   path: "/createScene",
      //   name: "CreateScene",
      //   meta: {
      //     index: 9,
      //     // title: i18n.t("create_a_new_scene"),
      //     title: "logcloud",
      //   },
      //   component: () => import("@/pages/scene/CreateScene.vue"),
      // },

      // {
      //   path: "/createSceneWithoutTemplate",
      //   name: "CreateSceneWithoutTemplate",
      //   meta: {
      //     index: 12,
      //     // title: i18n.t("create_a_new_scene"),
      //     title: "logcloud",
      //   },
      //   component: () => import("@/pages/scene/CreateSceneWithoutTemplate.vue"),
      // },

      // {
      //   path: "/createSceneWithTemplate",
      //   name: "CreateSceneWithTemplate",
      //   meta: {
      //     index: 13,
      //     // title: i18n.t("create_a_new_scene"),
      //     title: "logcloud",
      //   },
      //   component: () => import("@/pages/scene/CreateSceneWithTemplate.vue"),
      // },

      {
        path: "/createAlert",
        name: "CreateAlert",
        meta: {
          index: 9,
          // title: i18n.t("create_a_new_scene"),
          title: "logcloud",
        },
        component: () => import("@/pages/alert/CreateAlert.vue"),
      },

      {
        path: "/createShipment",
        name: "CreateShipment",
        meta: {
          index: 10,
          // title: i18n.t("create_a_new_scene"),
          title: "logcloud",
        },
        component: () => import("@/pages/shipment/CreateShipment.vue"),
      },


      
      {
        path: "/createAlertWithoutTemplate",
        name: "CreateAlertWithoutTemplate",
        meta: {
          index: 12,
          // title: i18n.t("create_a_new_scene"),
          title: "logcloud",
        },
        component: () => import("@/pages/alert/CreateAlertWithoutTemplate.vue"),
      },

      {
        path: "/createAlertWithTemplate",
        name: "CreateAlertWithTemplate",
        meta: {
          index: 13,
          // title: i18n.t("create_a_new_scene"),
          title: "logcloud",
        },
        component: () => import("@/pages/alert/CreateAlertWithTemplate.vue"),
      },

      {
        path: "/createShipmentWithoutTemplate",
        name: "CreateShipmentWithoutTemplate",
        meta: {
          index: 14,
          // title: i18n.t("create_a_new_scene"),
          title: "logcloud",
        },
        component: () => import("@/pages/shipment/CreateShipmentWithoutTemplate.vue"),
      },

      {
        path: "/createShipmentWithTemplate",
        name: "CreateShipmentWithTemplate",
        meta: {
          index: 15,
          // title: i18n.t("create_a_new_scene"),
          title: "logcloud",
        },
        component: () => import("@/pages/shipment/CreateShipmentWithTemplate.vue"),
      },

      {
        path: "/editShipment",
        name: "EditShipment",
        meta: {
          index: 16,
          // title: i18n.t("create_a_new_scene"),
          title: "logcloud",
        },
        component: () => import("@/pages/shipment/EditShipment.vue"),
      },


      
      {
        path: "/organDetail",
        name: "OrganDetail",
        meta: {
          index: 10,
          // title: i18n.t("organ_detail"),
          title: "logcloud",
        },
        component: () => import("@/pages/organ/OrganDetail.vue"),
      },

      // {
      //   path: "/sceneDetail",
      //   name: "SceneDetail",
      //   meta: {
      //     index: 11,
      //     // title: i18n.t("scene_detail"),
      //     title: "logcloud",
      //   },
      //   component: () => import("@/pages/scene/SceneDetail.vue"),
      // },

      // {
      //   path: "/alertDetail",
      //   name: "AlertDetail",
      //   meta: {
      //     index: 11,
      //     // title: i18n.t("scene_detail"),
      //     title: "logcloud",
      //   },
      //   component: () => import("@/pages/alert/AlertDetail.vue"),
      // },

      
    ],
  },
  {
    path: "/",
    name: "App",
    redirect: "/login",
    meta: {
      index: 1,
      title: i18n.t("login"),
    },
    component: App,
  },
  {
    path: "/login",
    name: "login",
    meta: {
      index: 1,
      // title: i18n.t("login"),
      title: "logcloud",
    },
    component: () => import("@/pages/login/Login.vue"),
  },
  // {
  //   path: "/forgetPassword",
  //   name: "ForgetPassword",
  //   meta: {
  //     index: 1,
  //     // title: i18n.t("forget_password"),
  //     title: "logcloud",
  //   },
  //   component: () => import("@/pages/login/ForgetPassword.vue"),
  // },
  // {
  //   path: "/home",
  //   name: "Home",
  //   meta: {
  //     index: 1,
  //     title: "主页",
  //   },
  //   component: () => import("@/pages/home/Home.vue"),
  // },

  // {
  //   path: "/board",
  //   name: "board",
  //   meta: {
  //     index: 1,
  //     title: "面板",
  //   },
  //   component: () => import("@/pages/board/Board.vue"),
  // },

  // {
  //   path: "/controlCenter",
  //   name: "controlCenter",
  //   meta: {
  //     index: 1,
  //     title: "控制中心",
  //   },
  //   component: () => import("@/pages/controlCenter/ControlCenter.vue"),
  // },

  {
    path: "/404",
    name: "404",
    component: () => import("@/pages/404"),
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// 在 beforeEach 守卫中更新页面标题
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    // 使用 i18n.t 获取翻译后的标题
    document.title = i18n.t(to.meta.title);
  }
  next();
});

export default router;
