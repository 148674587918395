/*
 * @Author       : JiangChao
 * @Date         : 2024-04-15 14:06:42
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-08 20:47:48
 * @Description  : 
 */
/*************************** 全局变量配置文件 ***************************/
const VUE_APP_UNIT = process.env.VUE_APP_UNIT;
const CONFIG = {};

CONFIG.dev = {
	baseURL: '',
	appId: '',
	isVConsole: false,
}

CONFIG.dat = {
	baseURL: 'http://logcloud.sigmawit.com',
	appId: '',
	isVConsole: false,
}
CONFIG.uat = {
	baseURL: '',
	appId: '',
	isVConsole: false,
}
CONFIG.prd = {
	baseURL: '',
	appId: '',
	isVConsole: false,
}

// console.log(`欢迎进入${VUE_APP_UNIT}环境`);
export default CONFIG[VUE_APP_UNIT];