/*
 * @Author       : JiangChao
 * @Date         : 2024-04-15 14:06:42
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-08-09 16:14:15
 * @Description  : 
 */

export default {
    selectedTimeZone: state => state.selectedTimeZone,
    selectedTempType: state => state.selectedTempType,
    selectedLanguage: state => state.selectedLanguage,
    
}