import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import state from './state'

Vue.use(Vuex)

const store = new Vuex.Store({
    state,  //定义属性
    getters, //计算属性
    actions, //提交一个 mutation
    mutations, // 状态变更
})
export default store